
.navigation-section h2{
    margin-bottom: 14px;
}
.navigation-section li{
    display: flex;
    align-items: center;
    justify-items: center;
    z-index: 2;
font-weight: 600;
}
.navigation-section svg{
   margin-bottom: -3px;
   margin-left: 10px;
   transition: 0.5s;
   width: 20px;
   height: 20px;
}
.navigation-section li path{
    stroke:white;
    opacity: 0.75;
    transition: 0.5s;
}
.nav-link{
    color:rgba(255, 255, 255, 0.75);
    text-decoration: none;
    font-size: 24px;
    background-color: #25272D;
    padding: 20px;
    padding-right: 30px;
    box-sizing: border-box;
    flex-direction: row;
    gap: 12px;
    border-radius: 8px;
    position: relative;
    z-index: 2;
    transition: 0.5s;
}


.nav-link:hover svg{
    transform: translateX(10px);
 }
 .nav-link:hover path{
    opacity:1;
 }
 .nav-link:hover{
    background: none;
    color:rgba(255, 255, 255, 1);
 }
 .nav-link::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, #3AA1FF -174.48%, #211E29 32.15%);
    opacity: 0;
    transition: 0.5s;
}
.nav-link:hover::before {
    opacity: 1;
}
.navigation-section ul{
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.nav-link--animated{
    animation: navLinkAnimation 4s ease ;
}
.nav-link--animated::before{
    animation: navLinkBeforeAnimation 2s ease ;
}
.nav-link--animated svg {
    animation: navLinkSVGAnimation 2s ease ;
}
@keyframes navLinkSVGAnimation {
    0% {
      transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes navLinkBeforeAnimation {
    0% {
      opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes navLinkAnimation {
    0% {
      transform: scale(1);
        background: none;

    }
    25% {
        transform: scale(1.05);
        background: none;

    }
    50% {
        transform: scale(1);
        background: none;
    }
    51% {
        background-color: #25272d00;
    }
    100% {
        background-color: #25272D;
    }
}


