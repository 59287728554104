.event-sector {
  height: fit-content;
  font-size: 24px;
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  opacity: 0.8; /* Элементы сразу видны */
  transform: scale(1); /* Нормальный размер */
  z-index: 5;
}

.events-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: space-between;

}

.event-sector.event-sector--animated::before {
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  width: calc(100% + 25px);
  height: calc(100% + 25px);
  z-index: -1;
  border-radius: 8px;
}

.events-section h2 {
  margin-bottom: 16px;
  width: 100%;
}

.event-sector a {
  opacity: 0.8;
  font-weight: 600;
  width: fit-content;
  color: rgb(4, 134, 255);
  text-decoration: none;
  font-size: 28px;
}

.event-sector span {
  font-weight: 500;
  margin-bottom: 4px;
  width: fit-content;
}

.event-image-container-ru {
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}

.event-image-container {
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: rgba(19, 19, 25, 0.5);
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;

}

.event-image-container img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
  object-fit: contain;
}

.event-image-container-en {
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}

.event-image-container-ru img {
  width: 100%;
  min-height: 130px;
}

.event-image-container-en img {
  width: 100%;
  min-height: 130px;
}

.event-sector:hover {
  opacity: 1;
  z-index: 6;
}

/* Удалены @keyframes bounceAnimation */

@media (max-width: 1995px) {
  .events-list-container {
    max-height: 930px;
    overflow-y: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(19, 19, 25, 0.5);
  }
  .event-sector--animated {
    opacity: 0.8;
    margin-bottom: 40px;
  }
}

@media (max-width: 3995px) {
  .events-list-container {
    max-height: 930px;
    overflow-y: auto;
    padding: 20px;
    border-radius: 10px;
  }
  .event-sector--animated {
    opacity: 0.8;
    margin-bottom: 40px;
  }
}

@media (max-width: 440px) {
  .event-sector {
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .event-sector a {
    font-size: 20px;
  }
  .event-sector span {
    font-size: 16px;
  }
  .event-image-container-ru img, .event-image-container-en img {
    min-height: 100px;
  }
  .event-description {
    font-size: 14px;
  }
  .events-list-container {
    max-height: 100px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {
  .events-section {
    flex-direction: column;
  }
  .events-section h2 {
    text-align: center;
  }
  .events-list-container {
    max-height: 300px;
    overflow-y: auto;
    padding: 20px;
    border-radius: 10px;
  }
  .event-sector--animated {
    opacity: 0.8;
    margin-bottom: 37px;
  }
}

@media (max-width: 320px) {
  .events-list-container {
    max-height: 200px;
  }
  .events-list-container {
    max-height: 100px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 10px;
  }
}

.event-description {
  font-size: 18px;
  color: #ffffff;
  width: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}