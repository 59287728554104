.MDFormatedText{
    color: rgba(255, 255, 255, 0.9);
    font-size: 25px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    position: relative; 
    max-width: 100%;
    column-gap: 4px;
}
.MDFormatedText figure{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    position: relative;
}
.MDFormatedText figure:hover{
   background-color: rgba(0, 0, 0, 0.5);
}
.MDFormatedText .big-img--container{
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: translate(0,-150%);
    opacity: 0;
    transition: 0.3s;
    will-change: translate ;
}
.MDFormatedText .big-img--wrapper{
    position: relative;
    pointer-events: none;
    width: 90px;
    height: 90px;
    will-change: translate ;

}
.MDFormatedText .big-img--wrapper .gift-tier{
    color:#fbc601;
    text-shadow: 0px 0px 10px red;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    left: 10px;
    top:0px;
    will-change: translate ;

}
.MDFormatedText .big-img--wrapper .gift-keyword-img{
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0px;
    bottom: 0px;
    object-fit: contain;
    z-index: 2;
}
.MDFormatedText .big-img{
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    background: rgb(54, 54, 54);
    border-radius: 6px;
    border: 1px black solid;
    box-sizing: border-box;
}
.formated-wrapper{
    position: relative;
}
.MDFormatedText .formated-wrapper:hover .big-img--container{
    transform: translate(0,-100%);
    opacity: 1;
 }
.MDFormatedText .small-img{
    width: 22px;
    height: 22px;
    object-fit: contain;
    background: rgb(54, 54, 54);
    border-radius: 4px;
    margin-bottom: -4px;
}

.MDFormatedText--wrath{
    color: var(--wrath-frame-color);
    text-decoration: underline;
    text-decoration-color: var(--wrath-frame-color);
}
.MDFormatedText--lust{
    color: var(--lust-frame-color);
     text-decoration: underline;
    text-decoration-color: var(--lust-frame-color);
}
.MDFormatedText--sloth{
    color: var(--sloth-frame-color);
    text-decoration: underline;
    text-decoration-color: var(--sloth-frame-color);
}
.MDFormatedText--glut{
    color: var(--glut-frame-color);
    text-decoration: underline;
    text-decoration-color: var(--glut-frame-color);
}
.MDFormatedText--gloom{
    color: var(--gloom-frame-color);
    text-decoration: underline;

    text-decoration-color: var(--gloom-frame-color);
}
.MDFormatedText--pride{
    color: var(--pride-frame-color);
    text-decoration: underline;

    text-decoration-color: var(--pride-frame-color);
}
.MDFormatedText--envy{
    color: rgb(173, 0, 173);   
    text-decoration: underline;
    text-decoration-color:rgb(173, 0, 173);   

}
.MDFormatedText--none{
    color: rgba(159, 105, 58, 1);
    text-decoration: underline;
    text-decoration-color: rgba(159, 105, 58, 1);
}
.description-weight{
    text-decoration: underline;
    color:rgb(241, 211, 77);
  }
  .condition{
    color: rgb(37, 133, 37);
  }
  .coins-coditions{
    text-decoration: underline;
  }
  .coins-coditions--fc{
    color:#f1e451;
  }
  .coins-coditions--cg{
    color:#ebbb84;
  }
  .coins-coditions--bc{
    color:#bf8c52;
  }
  .coins-coditions--cp{
    color:#f5a051;
  }
  .condition--onhit{
    color:rgb(37, 133, 37);
  }
  .condition--clashwin{
    color: orange;
  }
  .condition--clashablecounter{
    color: orange;
  }
  .condition--combatstart{
    color: rgb(37, 133, 37);
  }
  .condition--indiscriminate{
    color: rgb(251, 44, 44);
  }
  .condition--clashlose{
    color: rgb(251, 44, 44);
  }
  .condition--oncrit{
    color: rgb(37, 133, 37);
  }
  .condition--onuse{
    color: rgb(77, 77, 255);
  }
  .condition--onkill{
    color: rgb(37, 133, 37);
  }
  .condition--onevade{
    color: rgb(37, 133, 37);
  }
  .condition--afterattack{
    color: rgb(37, 133, 37);
  }
  .condition--hitafterclashwin{
    color: rgb(37, 133, 37);
  }
  .condition--headshit{
    color: rgb(2, 185, 2);
  }