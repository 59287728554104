.news-sector {
  height: fit-content;
  font-size: 24px;
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  opacity: 0;
  transform: scale(0);
  z-index: 5;
}
.news-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: space-between;
}
.news-section h2 {
  margin-bottom: 16px;
  width: 100%;
}
.news-sector a {
  opacity: 0.8;
  font-weight: 600;
  width: fit-content;
  color: rgb(4, 134, 255);
  text-decoration: none;
  font-size: 28px;
}
.news-sector span {
  font-weight: 500;
  margin-bottom: 4px;
  width: fit-content;
}
.news-image-container {
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}
.news-image-container img {
  transition: transform 1s ease;
  width: 100%;
  min-height: 130px;
}
.news-image-container img:hover {
  transform: scale(1.06); 
}
.news-sector--animated {
  animation: bounceAnimation 1s ease forwards;
  opacity: 0.8;
}
.news-sector:hover {
  opacity: 1;
  z-index: 6;
}
@keyframes bounceAnimation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}
@media (max-width: 1295px) {
  .news-sector {
    width: 48%;
  }
}
@media (max-width: 440px) {
  .news-sector {
    width: 100%;
  }
}
.news-description {
  font-size: 18px;
  color: #ffffff;
  width: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}