.entityFullInfo-skill{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: #131319;
    border-radius: 8px;
    padding: 20px 20px 30px 20px;
    gap:10px;
    box-sizing: border-box;
}
.entityFullInfo-skill-l{
    padding-top:30px;
    padding-left:60px;
    position: relative;
    height: 135px;
}

.entityFullInfo-skill-l img {
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.entityFullInfo-skill-r{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.skill-sin-frame{
    height: 8px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}
.skill-coinGrowth{
    color:#ebbb84;
    font-size: 24px;
    position: absolute;
    top:0;
    transform: translate(-50%,-100%) ;
    left:50%;
    cursor: pointer;
    -webkit-text-stroke: 0.1px black;
    text-decoration: underline 1.5px #ebbb84;
    text-underline-offset: 2px;
}

.tooltip-container:hover .entityFullInfo-tooltip{
    opacity: 1;
    transform: translate(-50%,-100%) ;
}
.skill-baseCoin{
    font-size: 60px;
    color:#bf8c52;
    -webkit-text-stroke: 0.4px black;
    cursor: pointer;
    box-decoration-break: clone;
    position: relative;
    text-decoration: underline 2px #bf8c52;
    text-underline-offset: 2px;

}
.skill-coinCount{
    position: relative;
}
.entityFullInfo-coins{
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    position: relative;
}   
.entityFullInfo-coins img{
    width: 35px;
    height: 35px;
}
.skill-name{
    color:rgba(255, 255, 255, 0.9);
    width: fit-content;
    border-radius: 2px;
    padding: 2px;
    padding: 5px 10px;
    font-size: 30px;
    box-sizing: border-box;
    -webkit-text-stroke: 0.1px black;
    margin: 0 ;
    font-weight: bold;

}
.entityFullInfo-skill-index{
    color: rgba(255, 255, 255, 0.9);
    font-size: 30px;
    position: absolute;
    right: 20px;
    top:10px;
    font-weight: bold;
}
.skill-atk{
    color:rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    position: relative;
    gap:2px;
    cursor:pointer;
    font-size: 25px;
    font-weight: 500;
    text-decoration: underline 1.5px;
    text-underline-offset: 2px;
}
.skill-atk img{
    width: 35px;
    height: 35px;
    object-fit: contain;
}
.skill-weight{
    color:rgb(241, 211, 77);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    gap:5px;
    cursor:pointer;
    font-size: 25px;
    font-weight: 500;
    text-decoration: underline 1.5px;
    text-underline-offset: 2px;
}
.skill-weight div{
    width: 14px;
    height: 14px;
    background-color: rgb(241, 211, 77);
}
.skill-weight--buffed div{
    background-color:rgb(241, 192, 77) ;
}
.skill-count{
    color:rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    position: relative;
    gap:2px;
    cursor:pointer;
    font-size: 45px;
    font-weight: 500;
    text-decoration: underline 1.5px;
    text-underline-offset: 2px;
    margin-left: -13px;
}
@media (max-width:800px){
 .entityFullInfo-skill{
   padding-top: 50px;
    }
}
@media (max-width:600px){
    .skill-name{
        width: 100%;
    }
    .skill-count{
        position: static;
        margin-left: 0px;
    }
    .skill-weight{
        position: static;
    }
    .skill-atk{
        position: static;
    }
    .skill-baseCoin{
        position: static;
    }
    .skill-coinCount{
        position: static;
    }
    .skill-coinGrowth .entityFullInfo-tooltip{
        max-width: 50px;
    }
    
}