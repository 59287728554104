.tb-tags{
    margin-top: 16px;
    width: 90%;
    display: flex;
    flex-direction: column;
    color: white;
}
.tb-tags-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 113;
    position: sticky;
    margin-top: 8px;
    flex-wrap: wrap;
    gap: 4px;
}
.tb-tags-header{
    font-size: 24px;
    color: white;
    margin: 0;
}
.tb-tags-tag{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #25272D;
    width: 65px;
    height: 45px;
    color: white;
    border-radius: 4px;
    padding: 0 10px;
    position: relative;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}
.tb-tags-tag--active{
    box-shadow: 0 0 12px #3AA1FF; 
}
.tb-tags-img{
    width:30px;
    height:30px;
    object-fit:contain;
    margin-right: 4px;
}
@media(max-width:1000px){
    .tb-tags-container{
        z-index: 90;
    }
}