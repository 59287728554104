.search {
  display: flex;
  padding: 8.8px 16px;
  padding-right: 54px;
  align-items: center;
  gap: 6.4px;
  border-radius: 3.2px;
  background: #25272D;
  position:relative;
}
.search .btn-search {
  position: absolute;
  right: 8px;
  top:2px;
}
.search .btn-clear {
  position: absolute;
  right: 30px;
  top:2px;

}
.search button svg {
  padding-top: 4.8px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
}

.search input {
  background: #25272D;
  border: 0;
  color: #CECDD7;
  font-size: 16px;
  font-style: normal;
  width: 138px;
  height: 24px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.search input:focus {
  outline: none;
}

.search input::placeholder {
  color: #CECDD7;
}

.search-loader {
  width: 100%;
  height: 1.6px;
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: 0.32s;
}

.search-gray-line {
  width: 100%;
  height: 100%;
  background-color: #888;
  position: absolute;
  animation: moveGrayLine 1.6s linear infinite;
}

.search-white-line {
  width: 16px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  animation: moveWhiteLine 1.6s linear infinite;
}

.search-loader--hidden {
  opacity: 0;
}

@keyframes moveGrayLine {
  0% {
      left: -100%;
  }
  100% {
      left: 100%;
  }
}

@keyframes moveWhiteLine {
  0% {
      left: -16px;
  }
  100% {
      left: 100%;
  }
}
@media (max-width: 810px) {
  .search {
    position: relative;
    margin-right: auto;
    top:0;
  }
}
@media (max-width: 418px) {
  .search {
    position: relative;
    margin-right: auto;
    top:0;
  }
}
