.GiftsHeader-wrapper{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.GiftsHeader-info , .GiftsHeader-desc{
    display: flex;
    color: white;
    opacity:0.8;
    border: 1px grey dashed;
    font-size: 24px;
    min-height:250px;
    padding: 10px 20px;
    box-sizing: border-box;
    gap:8px;
}
.GiftsHeader-name{
    font-size: 36px;
    min-height:50px;
    box-sizing: border-box;
    color: white;
    font-weight: 600;
}
.GiftsHeader-centered{
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}
.GiftsHeader-info {
    width: 30%; 
    flex-direction: column;
}

.GiftsHeader-desc{
    width: 70%; 
}
.GiftsHeader-highlight-text{
    font-weight: 700;
    color: rgb(254, 183, 52);
    margin-right: 4px;
}
@media (max-width:1250px) {
    .GiftsHeader-wrapper{
        width:100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }
    .GiftsHeader-info{
        order: 2;
        flex-grow: 1;
    }
    .gift-wrapper{
        order: 1;
    }
    .GiftsHeader-desc{
        order: 3;
        flex-grow: 1;

    }
    
}
@media (max-width:600px){
    .GiftsHeader-name {
        display: flex;
        justify-content: center;
    }
    .GiftsHeader-wrapper{
        justify-content: center;
    }
    .GiftsHeader-info{
        order: 3;
        flex-grow: 1;
    }
    .gift-wrapper{
        order: 1;
    }
    .GiftsHeader-desc{
        order: 2;
        flex-grow: 1;

    }
}