.TierListDisclaimer{
  display: flex;
  width:90%;
  flex-direction: column;
  margin-bottom: 24px;
}
.disclaimer-link{
  color:#3AA1FF;
}
.condition--green{
  color:#258525;
}
.TierListDisclaimer p{
  box-sizing: border-box;
  padding: 15px 15px 15px 15px;
  background-color: #131319;
  border-radius: 6px;
  color: white;
  font-weight: 500 !important;
  margin: 0;
  font-size: 20px;
}
.TierListDisclaimer h2{
  margin: 0;
  display: flex;
  flex-direction: row;
  gap:5px;
  font-size: 32px;
  color: white;
  box-sizing: border-box;
  position: relative;
  align-items: center;
}