.mobile-info-modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.691);
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    z-index: 201;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none;
    perspective: 1600px;
    user-select: none;
}
.mobile-info-modal--active{
    opacity: 1;
    pointer-events: all;
}
.mobile-info-modal__content{
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    position: relative;
    transition: 0.5s;
    justify-content: center;
    align-items: center;
}
