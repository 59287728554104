.md-event-option {
    margin-bottom: 10px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 3;
    align-items: flex-end;
    gap:12px;
  }
  .md-event-option  *{
    box-sizing: border-box;
  }
  .option-text{
    color: white;
    font-size: 24px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    padding: 0 25%;
  }
  .option-name{
    background-color: #0b0b0e;
    color: white;
    font-size: 24px;
    width: 100%;
    padding: 5px;
    border: 2px solid black;
    border-radius: 8px;
    cursor: pointer; 
    transition: 0.3s;
    align-items: center;
    display: flex;
    padding: 10px 20px;
    gap:10px;
    font-weight: 600;
  }
  .option-description{
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    text-overflow: clip;
  }
  .option-description span{
    font-style: italic;
    font-weight: 500;
    font-size: 17.5px;
  }
  .option-name svg{
    transition: 0.3s;
    transform: rotate(180deg);
  }
 .collapsed svg{
    transform: rotate(0);
  }
  .option-name:hover{
    border: 2px solid rgb(145, 145, 145);

  }
  .option-text::after {
    margin-left: 5px;
  }
  .check-check{
    font-size: 30px;
  }
  .check-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .check-item-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: 24px;
    gap:3px;
  }
  .check-item {
    font-size: 24px;
    display: flex;
    gap:3px;
    align-items: center;
  }
  .check-info img{
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  
  .option-result-wrapper{
    width:100%;
    position: relative;
    padding: 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap:4px;
    box-sizing: border-box;
    border: 2px #3AA1FF solid;
    border-radius: 6px;
    padding: 10px 20px;
    background-color: #1C1E23;
    text-align: center;
  }
  .option-result--combat{
    border: none !important;

  }
  .check-passed-header img, .check-failed-header  img{
    width: 25px;
    height: 25px;
    object-fit: contain ;
  }
  .check-passed-header, .check-failed-header {
    background-color: #0b0b0e;
    text-transform: uppercase;    
    width:100%;
    font-size: 24px;
    text-align: center;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:4px;
    padding: 5px 0px;
  }
  .option-result{
    width:100%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .check-passed-header {
    color: green;
    border: 2px solid green;
  }
  
  .check-failed-header  {
    color: red;
    border: 2px solid red;

  }
  .option-combat-wrapper{
    width: 100%;
    box-sizing: border-box;
    border: 2px #3AA1FF solid;
    border-radius: 6px;
    padding: 10px 0;
    background-color: #1C1E23;
    gap:12px;
    display: flex;
    flex-direction: column;
  }
  .option-combat-header{
    width: 100%;
    text-align: center;
    color: red;
    font-size: 26px;
  }
  .option-combat-items{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }
  .option-combat-items figure{
   margin: 0;
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 4px;
  }
  .option-combat-items img{
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 8px;
   }
  .option-combat-items figcaption{
    width: min-content;
    min-width: 100px;
    color: burlywood;
    padding: 0 2px 0 2px;
    box-sizing: border-box;
    text-align: center;
   }