.main-info{
    color:white;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.main-info h2{
    font-size: 42px;
}
.main-info-header-main {
    min-height: 40vh;
    margin-top: 36px;
    margin-bottom: 34.86px;
    position: relative;
    z-index: 3;
    padding: 40px;
    box-sizing: border-box;
    width: 100%;
    border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
    border-radius: 5px; /* Закруглите углы для более эстетического вида */
  }
  
.main-info-header-main h1{
   font-size: 90px;
   margin: 0;
   animation: AppearAnimationH1 1s ease;
}

.support-link {
    color:#3AA1FF;
}
.main-info-header-description{
    max-width: 550px;
}
@keyframes AppearAnimationH1 {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
    }
}
.main-info-header-main h1 span{
    animation: AppearAnimationH1SPAN 1s ease 1s forwards;
}    

@keyframes AppearAnimationH1SPAN {
    0% {
        color: white;
    }
    100% {
        color: #3AA1FF;
    }
}
.main-info-header-main p{
    margin: 0;
    font-weight: 500;
   animation: AppearAnimationP 1s ease;
 }
 @keyframes AppearAnimationP {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
    }
  }
.main-info-header-main::before {
    content: "";
    left: 0;
    top: -36px;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(94deg, rgba(0, 0, 0, 0.50) 40.7%, rgba(0, 0, 0, 0.00) 69.47%), url("../../../public/images/limbus.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 36px;
    z-index: -1;
    opacity: 0.9;
    animation: AppearAnimation 2s ease;
}
@keyframes AppearAnimation {
    0% {
        opacity: 0.3;
      }
      100% {
        opacity: 0.9;
      }
  }


.main-info p {
    font-size: 24px;
}
.main-info-left{
    width: 70%;
}
.main-info-right{
    width: 25%;
}
.rek-image-container {
    width: 100%; /* Изменено с 120rem на 100% для адаптивности */
    max-width: 120rem; /* Ограничение максимальной ширины */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto; /* Центрирование контейнера */
}
.reklama-image, .reklama-image2 {
    width: 35rem;
    height: auto;
    border: 7px solid rgba(19, 19, 25, 1);
    margin: 0.5rem 0; /* Отступы только сверху/снизу */
    cursor: pointer;
}
.jab-image {
    width: 13rem;
    height: auto;
    margin: 0.5rem 0; /* Отступы только сверху/снизу */
    cursor: pointer;
    transition: transform 0.3s ease, filter 0.3s ease;
    cursor: pointer;
    will-change: transform, filter;
}
.jab-pressed {
    transform: scale(0.95);
    filter: brightness(1.1);
}
@media (max-width: 3295px) {
    .reklama-image {
        width: 35rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1);
        
    }
    .reklama-image2 {
        width: 35rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1);
        
    }
   
}
@media (max-width: 1595px) {
    .reklama-image, .reklama-image2{
        width: 35rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .jab-image {
        width: 0rem;
    }
}
@media (max-width: 1295px) {
    .main-info section {
        width: 100%;
    }
    .main-info-left {
        order: 1;
    }
    .reklama-image, .reklama-image2{
        width: 35rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .jab-image {
        width: 0rem;
    }
}

/* Мобильная версия (вертикальное расположение) */
@media (max-width: 768px) {
    .rek-image-container {
        flex-direction: column;
        align-items: center;
    }

    .reklama-image, .reklama-image2 {
        width: 100%; /* Занимают всю ширину контейнера */
        max-width: 35rem; /* Но не растягиваются больше исходного размера */
        margin: 0.5rem 0; /* Отступы между изображениями */
    }
    .jab-image {
        width: 0rem;
        height: auto;
        margin: 0.5rem 0; /* Отступы только сверху/снизу */
        cursor: pointer;
    }
}

@media (max-width: 730px) {
    .main-info-header-main h1{
        font-size: 70px;
     }
     .main-info-header-main p{
        font-size: 22px;
     }
     .reklama-image{
        width: 30rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .jab-image {
        width: 0rem;
        height: auto;
        margin: 0.5rem 0; /* Отступы только сверху/снизу */
        cursor: pointer;
    }
}
@media (max-width: 730px) {
    .main-info-header-main h1{
        font-size: 70px;
     }
     .main-info-header-main p{
        font-size: 22px;
     }
     .main-info-header-main{
        padding:30px;
    }
    .jab-image {
        width: 0rem;
        height: auto;
        margin: 0.5rem 0; /* Отступы только сверху/снизу */
        cursor: pointer;
    }
}
@media (max-width: 505px) {
    .main-info-header-main h1{
        font-size: 70px;
     }
     .main-info-header-main p{
        font-size: 20px;
     }
     .main-info-header-main{
        padding:20px;
    }
    .reklama-image{
        width: 25rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .reklama-image2{
        width: 25rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .jab-image {
        width: 0rem;
        height: auto;
        margin: 0.5rem 0; /* Отступы только сверху/снизу */
        cursor: pointer;
    }
}
@media (max-width: 450px) {
    
     .main-info-header-main p{
        font-size: 18px;
     }
     .reklama-image{
        width: 21rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .reklama-image2{
        width: 21rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .jab-image {
        width: 0rem;
        height: auto;
        margin: 0.5rem 0; /* Отступы только сверху/снизу */
        cursor: pointer;
    }
     
}

@media (max-width: 380px) {
    
    .main-info-header-main p{
       font-size: 18px;
    }
    .reklama-image{
       width: 20rem;
       height: auto;
       border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
    }
    .reklama-image2{
        width: 20rem;
        height: auto;
        border: 7px solid rgba(19, 19, 25, 1); /* Добавлена обводка */
     }
     .jab-image {
        width: 0rem;
        height: auto;
        margin: 0.5rem 0; /* Отступы только сверху/снизу */
        cursor: pointer;
    }
    
}

.panel {
    position: fixed;
    top: 10px;
    right: 20px;
    background-color: #333;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    width: 400px;
}

.panel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    width: 100%;
    margin-bottom: 20px;
}

.input-field {
    width: 90%;
    height: 30px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #555;
    border-radius: 2px;
}

.hint-text {
    font-size: 14px;
    color: #ccc;
    margin-top: 5px;
}

.button-clear-cache {
    width: 100%;
    height: 35px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 20px;
}

.button-clear-cache:hover {
    background-color: #0056b3;
}

.console-container {
    width: 100%;
    height: 150px;
    background-color: #464646;
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 5px;
}

.console-header {
    background-color: #000000;
    padding: 2px;
    border-bottom: 1px solid #ddd;
    color: white;
}

.console-logs {
    padding: 2px;
    overflow-y: auto;
    max-height: calc(100% - 30px);
    color: white;
}

.console-logs p {
    margin-bottom: 2px;
}
