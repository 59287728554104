.entityFullInfo-skills{
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (max-width:1200px) {
    .entityFullInfo-skills{
        width: 100% ;
    }
}

.entityFullInfo-skills .entityFullInfo-tooltip{
    max-width: 190px;
    text-overflow: ellipsis; 
}