.ListSinnerBar{
    width:100%;
    display: flex;
    flex-direction: column;
}
.item-entity-container {
    transition: 0.5s;
}
.item-entity-container {
    transition: 1s;
}
.ListSinnerBar .sinner-default-header{
    margin: 0;
    opacity: 0;
}
.ListSinnerBar .sinner-default-header--animated{
    animation: animHeaderSinner 1s 0.2s ease-out forwards;
}
.ListSinnerBar header{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap:20px;
    margin-bottom: 20px;
}
@media(max-width:600px){
    .ListSinnerBar header{
        flex-direction: column-reverse;
        align-items: flex-start;
    }  
}
.sinner-default-new {
    width: 300px;
    height: 60px;
    object-fit: cover;
    object-position: 0% 75%;
    border-radius: 8px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 1);
    opacity: 0; 
    transform: translateY(20px); 
    background-color: rgba(255, 57, 57, 0.679);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    text-shadow: 
    -2px -2px 0 #000, 
     2px -2px 0 #000,
    -2px  2px 0 #000,
     2px  2px 0 #000;
}
.sinner-default-new--animated {
    animation: fadeInUp 1s ease-out forwards;
}

.sinner-default-img {
    width: 300px;
    height: 60px;
    object-fit: cover;
    object-position: 0% 75%;
    border-radius: 8px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 1);
    opacity: 0; 
    transform: translateY(20px); 
}
.sinner-default-img--animated {
    animation: fadeInUp 1s ease-out forwards;
}
@keyframes animHeaderSinner {
    to {
        opacity: 1;
    }
}
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0); 
        object-position: 0% 45%;
    }
}
.ListSinnerBar-contentWrapper {
    width: fit-content;
    display: flex;
    flex-direction: column;
}
.ListSinnerBar-content {
    display: flex;
    flex-wrap: wrap;
    gap: 9.6px;
    margin-bottom: 20px;
}
