.gift-wrapper{
    position: relative;
    display: block;
    width: 250px;
    height: 250px;
    cursor: pointer;
    overflow: hidden;
    flex-shrink: 0;
}
.gift-shadow{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 3;
}
.gift-wrapper svg{
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    z-index: 3;
    transition: 0.3s;
    transform: translate(-50%,-50%) scale(0);
    width: 80px;
    height: 80px;
}
.gift-wrapper:hover .gift-shadow{
    background-color: rgba(0, 0, 0, 0.5);
}
.gift-wrapper:hover svg{
    transform: translate(-50%,-50%) scale(1);
}

.gift-main-img{
    object-fit: contain;
    width: 250px;
    height: 250px;
    display: block;
    background-color: #131319;

}
.gift-wrapper p{
    object-fit: contain;
    width: 250px;
    height: 250px;
    color: white;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    border: dashed grey 1px;
    box-sizing: border-box;
    margin: 0;
    font-size: 24px;
    text-align: center;
}
.gift-btn-upgrade{
    color: white;
    position: absolute;
    top: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    background-color: rgba(113, 113, 113, 0.176);
    font-weight: bold;
    font-size: 30px;
    z-index: 4;
}
.gift-tier{
    color:#fbc601;
    text-shadow: 0px 0px 10px red;
    font-size: 75px;
    font-weight: bold;
    position: absolute;
    left: 20px;
    top:5px;
}
.gift-btn-upgrade--false:hover{
    background-color: rgba(0, 0, 0, 1);
}
.gift-btn-upgrade:disabled {
  opacity:0.3;
  cursor: not-allowed;
}
.gift-btn-upgrade--selected{
    background-color: rgb(246, 157, 24);
    color: black;
}
.gift-btn-upgrade--1{
    right: 80px;
}
.gift-btn-upgrade--2{
    right: 40px;
}
.gift-btn-upgrade--3{
    right: 0;
}
.gift-bar{
    position: absolute;
    height: 8px;
    width: 100%;
    bottom: 0;
    left: 0;
}
.gift-bar--wrath{
    background-color: var(--wrath-frame-color);
}
.gift-bar--lust{
    background-color: var(--lust-frame-color);
}
.gift-bar--sloth{
    background-color: var(--sloth-frame-color);
}
.gift-bar--glut{
    background-color: var(--glut-frame-color);
}
.gift-bar--gloom{
    background-color: var(--gloom-frame-color);
}
.gift-bar--pride{
    background-color: var(--pride-frame-color);
}
.gift-bar--envy{
    background-color: var(--envy-frame-color);
}
.gift-bar--none{
    background-color: var(--none-frame-color);
}
.gift-keyword-img{
    position: absolute;
    width: 80px;
    height: 80px;
    right: 0px;
    bottom: 0px;
    object-fit: contain;
    z-index: 2;
}