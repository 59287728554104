@font-face {
  font-family: 'Alumni';
  src: url('../public/fonts/alumni/AlumniSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Alumni';
  src: url('../public/fonts/alumni/AlumniSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Alumni';
  src: url('../public/fonts/alumni/AlumniSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
}

:root {
  --OOO-color: rgba(255, 255, 255, 0.60);
  --OO-color: rgba(255, 255, 255, 0.60);
  --O-color: rgba(255, 255, 255, 0.60);
  --OOO-frame-color:#FFBC3A;
  --OO-frame-color:#A83129;
  --O-frame-color:#724501;
  --wrath-color: #f0d3af;
  --lust-color: #f0d3af;
  --sloth-color: #f0d3af;
  --glut-color: #f0d3af;
  --gloom-color: #f0d3af;
  --pride-color: #f0d3af;
  --envy-color: #f0d3af;
  --wrath-frame-color: #c9100f;
  --lust-frame-color: #e66728;
  --sloth-frame-color: #f2b53f;
  --glut-frame-color: #6c9c20;
  --gloom-frame-color: #16849b;
  --pride-frame-color: #185188;
  --envy-frame-color: #5c0288;
  --none-frame-color: #9f693a;
  --SSS-color: rgb(236, 24, 48);
  --SS-color: rgba(255, 97, 74);
  --S-color: rgba(255, 188, 58);
  --A-color: rgb(143, 255, 58);
  --B-color: rgb(58, 255, 166);
  --C-color: rgb(58, 255, 248);
  --Test-color: rgb(75, 3, 53);
  --grey-0:#2C2C2C;
  --grey-1:#494747;
  --grey-2:#4D4B4B;
  --season-w-color: #77cd03;
  --season-s-0-color: #feb600;
  --season-s-1-color: #970000;
  --season-s-2-color: #daeaf2;
  --season-s-3-color: #27c0c3;
  --season-s-4-color: #7d57a7;
  --season-s-5-color: #f9f034;
  --season-s-6-color: #fff;
  --season-s-7-color: #fff;
  --season-s-8-color: #fff;
  --season-s-9-color: #fff;
  --season-s-10-color: #fff;
  --season-s-11-color: #fff;
  --season-s-12-color: #fff;
}
.wrath-sin-color{
  background-color:var(--wrath-frame-color);
}
.lust-sin-color{
  background-color:var(--lust-frame-color);
}
.sloth-sin-color{
  background-color:var(--sloth-frame-color);
}
.glut-sin-color{
  background-color:var(--glut-frame-color);
}
.gloom-sin-color{
  background-color:var(--gloom-frame-color);
}
.pride-sin-color{
  background-color:var(--pride-frame-color);
}
.envy-sin-color{
  background-color:var(--envy-frame-color);
}

.shadow::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(143deg, rgba(0, 0, 0, 0.40) 17.06%, rgba(0, 0, 0, 0.00) 52.01%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 10.42%, rgba(0, 0, 0, 0.60) 84.37%);
  border-radius: 8px 8px;
}
.display-none{
  display: none !important;
}

*{
  font-family: 'Alumni', sans-serif !important;
} 


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #1C1E23;

}

::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(93, 92, 92);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(107, 107, 107);
}
.perCent-special-font{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
  font-size: 0.88em;
  opacity: 0.9;
}
h1 {
  font-size: 42px;
}


