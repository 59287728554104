.statuses-table{
    margin-top: 1em;
    margin-bottom: 1em;
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#AFAEB4;
    font-size: 19.2px;
    font-weight: 400;
}
td .perCent-special-font{
    font-size: 20px;
}
.statuses-section:nth-of-type(2),
.statuses-section:nth-of-type(3),
.statuses-section:nth-of-type(4),
.statuses-section:nth-of-type(5) h2{
    margin-top: 15px;
}
.statuses-section .search {
    margin-top: 2em;
    width: 100%;
    box-sizing: border-box;
}
.statuses-section .search input {
    width: 80%;
}
.statuses-section .search .btn-clear {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translate(0,-50%);
}
.statuses-section .search .btn-search {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
}
.status-tooltip{
    display: block; 
    position: absolute;
    text-align: center;
    width: max-content;
    max-width: 95px;
    background-color: #333;
    color: #fff;
    font-size: 14.4px;
    padding: 4px 6px;
    border-radius: 4px;
    z-index: 2;
    top: -1.6px;
    transform: translate(0,-150%);
    font-weight: 500;
    opacity: 0;
    transition: 0.4s;
    pointer-events: none;
    border: 1px solid black;
    box-sizing: border-box;
}
button:hover .status-tooltip{
    opacity: 1;
    transform: translate(0,-100%);

}
.statuses-table img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.statuses-table-th-image{
    width: 10%;
}

.statuses-table-th-name{
    width: 20%;
}

.statuses-table-th-description{
    width: 70%;
    padding: 8px;
    box-sizing: border-box;
}

.statuses-table tbody tr{
    transition: 0.56s;
}

.statuses-table-tr--active{
    box-shadow: 0 0 9.6px #3AA1FF;
}

.statuses-table tbody tr:hover {
    transform: scale(1.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.statuses-table thead tr{
    background-color: #121315;
}

.statuses-table thead th{
    font-size: 25.6px;
}

.statuses-table tbody td{
    font-weight: 400;
    font-size: 22.4px;
}

.statuses-table tbody tr:nth-child(even) {
    background-color: #202126;
}

.statuses-table tbody tr:nth-child(odd) {
    background-color: none; 
}

.statuses-buttons button img{
    width: 27.2px;
    height: 27.2px;
    object-fit: contain;
}

.statuses-buttons{
    position: relative;
    display: grid;
    gap: 3.2px;
    grid-template-columns: repeat(auto-fill, 43.2px);
}

.statuses-buttons button{
    width: 43.2px;
    height: 43.2px;
    color:white;
    font-size: 19.2px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    margin-right: 3.2px;
    border-radius: 3.2px;
    cursor: pointer;
    position: relative;
    background: #202126;
    transition: 0.32s;
}

.statuses-buttons button:hover{
    box-shadow: 0px 6.4px 13.6px 1.6px rgba(0,0,0,0.112) ,0px 2.4px 11.2px 1.6px rgba(0,0,0,0.096) ,0px 4px 4px -2.4px rgba(0,0,0,0.16);
}

.statuses-section{
    width: 90%;
}

.statuses-section h2 {
    color: white;
}
.statuses-section h2 {
    margin-top: 0;
    margin-bottom: 0.25em;
}
@media (max-width: 665px) {
    .statuses-table{
        width: 90%;
    }
    .statuses-table tbody td{
       font-size: 0.8em;
    }
    td .perCent-special-font{
        font-size: 0.8em;
    }
}
@media (max-width: 370px) {
    .statuses-table tbody td{
       font-size: 0.7em;
    }
    td .perCent-special-font{
        font-size: 0.7em;
    }
}