.TooltipMobile{
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    background-color:#131319;
    border-radius: 8px;
    color: white;
    max-width: 300px;
    align-items: center;
}
.TooltipMobile hr {
    box-sizing: content-box;
    overflow: visible;
    width: 100%;
    opacity: 0.65;
}
.TooltipMobile img{
    width: 40px;
    height: 40px;
    object-fit: contain;
    display: inline;
}
.TooltipMobile-header{
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
}
.TooltipMobile-info{
    position: absolute;
    width: max-content;
    max-width: 240px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 4px;
    left:50%;
    -webkit-text-stroke: 0px !important;
    pointer-events: none;
    opacity: 1;
    z-index: 2;
    position: unset;
    transform: translate(0%,0%);
    transition: 0s;
    background: none;
}

@media (min-width:1250px) {
    .TooltipMobile-header{
        font-size: 40px;
    }
    .TooltipMobile{
        max-width: 700px;
    }
    .TooltipMobile-info{
        font-size: 24px;
        max-width:  600px;
    }
}
@media (min-width:1900px) {
    .TooltipMobile-header{
        font-size: 50px;
    }
    .TooltipMobile{
        max-width: 900px;
    }
    .TooltipMobile-info{
        font-size: 30px;
        max-width:  800px;
    }
}