.top-menu{
    display: none;
}
@media (max-width: 1000px) {
    .top-menu{
      display: flex;
      height: 70px;
      width: 100%;
      background-color: #131319;
      padding: 20px 30px 20px 30px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      z-index: 103;
    }
    .humburger-menu{
        position: relative;
        color: white;
        font-size: 32px;
        z-index: 2;
        width: 32px;
        height: 20px;
        line-height: 0;
        transition: 0.8s;
        left: unset;
        display: flex;
        align-items: center;
    }
    .humburger-menu .line{
        background-color: #fff;
        transition: 0.4s;
        width: 100%;
        height: 3.2px;
    }
    .humburger-menu::after {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 3.2px;
        background-color: #fff;
        transition: 0.4s;
        right: 0;
    }
    .humburger-menu::before {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 3.2px;
        color: white;
        background-color: #fff;
        transition: 0.4s;
        right: 0;
    }
    .humburger-menu--expanded.humburger-menu::before{
        transform: translateY(-8.5px) rotate(-45deg) ;
    }
    .humburger-menu--expanded.humburger-menu::after{
        transform: translateY(8.5px) rotate(45deg) ;
    }
    .humburger-menu--expanded .line{
        opacity: 0;
    }
    .first-render .humburger-menu{
        transition: 0s;
    }
    .first-render .humburger-menu .line{
        transition: 0s;
    }
    .first-render .humburger-menu::after{
        transition: 0s;
    }
    .first-render .humburger-menu::before{
        transition: 0s;
    }
    .top-menu .website-title{
        color: #fff; 
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 24px;
        gap:8px;
    }
    .top-menu a{
        text-decoration: none;
    }
    .top-menu span span{
        color: #3AA1FF;
    }
}
