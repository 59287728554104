.tb-info{
    background: #535353;
    width: 232px;
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    border-radius: 4px;
    display: none;
}
.tb-sins-sin:hover .tb-info{
    display: flex;
}
.tb-tags-tag:hover .tb-info{
    display: flex;
}

.tb-sins-sin .tb-info{
    transform: translate(calc(-50% + 32.5px) , calc(-100% - 15px));
}
.tb-tags-tag .tb-info{
    transform: translate(calc(-50% + 42.5px) , calc(-100% - 15px));
}
.tb-info-header{
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 8px;
}
.tb-info-header:nth-of-type(1){
    margin-top: 0px;
}
.tb-info-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill,55px);
    gap: 4px;    
}
.tb-info-container div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.tb-info-img{
    object-fit: contain;
    width: 35px;
    height: 35px;
}
.tb-info-count{
    font-size: 22px;
}
.tb-info-arrow {
    width: 14.4px;
    height: 14.4px;
    transform: rotate(-45deg);
    background: #535353;
    position: absolute;
    bottom: -7.2px;
    left: calc(50% - 7.2px);
    z-index: 98;
}
@media(max-width:1000px){
    .tb-info-arrow{
        display: none;
    }
    .tb-info{
        transform: translate(-50%,-50%);
        left: 50%;
        top: 50%;
        display: flex;
        color:white;
    }
}