  .about-game-info {
    width: 90%;
    min-height: 100vh;
    color: white;
    z-index: 1; 
  }

.about-game-info p{
    font-size: 26px;
    font-weight: 400;
}
.about-game-info h2 {
    font-size: 32px;
}
.about-game-info span {
    color: #3AA1FF;
}
.about-game-info img {
    width: 100%;
    object-fit: contain;
    opacity: 0.8;
}


