.disclaimer-banner{
    width: 100%;
    height: 20vh;
    display: flex;
    box-sizing: border-box;
    color: white;
    position: fixed;
    background-color: #ffffff;
    z-index: 112;
    font-size: 13px;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    gap:20px;
    z-index:1000;
}
.disclaimer-banner p {
    margin: 0;
    color: #121315;
}
.disclaimer-banner span {
    color: #3AA1FF;
}
.disclaimer-banner button {
    border-radius: 6px;
    padding: 10px 30px;
    background-color:  #3AA1FF;
    box-sizing: border-box;
    transition: 0.5s;
}
.disclaimer-banner button:hover {
    background-color: #0471d6;
}
@media (min-width: 380px) {
    .disclaimer-banner {
        font-size: 15px;
    }
}
@media (min-width: 510px) {
    .disclaimer-banner {
        font-size: 17px;
    }
}
@media (min-width: 700px) {
    .disclaimer-banner {
        font-size: 19px;
    }
}
@media (min-width: 900px) {
    .disclaimer-banner {
        font-size: 21px;
    }
}
@media (min-width: 1100px) {
    .disclaimer-banner {
        font-size: 23px;
    }
}
@media (min-width: 1300px) {
    .disclaimer-banner {
        font-size: 25px;
    }
}
@media (min-width: 1500px) {
    .disclaimer-banner {
        font-size: 27px;
    }
}
@media (min-width: 1700px) {
    .disclaimer-banner {
        font-size: 29px;
    }
}
@media (min-width: 1900px) {
    .disclaimer-banner {
        font-size: 31px;
    }
}