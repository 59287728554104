.guide-page .inline{
  display: flex;
}
/* Контейнер для всей страницы гайда */
.guide-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Выровнено влево */
  padding-left: 0; /* Убираем внутренний отступ слева для контейнера */
  width: 90%;
  font-size: 1.5rem;
}

.guide-page .guide-header .guide-tags-container .dropdown-tag-item{
  border: none;
}

.guide-page .guide-header .guide-tags-container .dropdown-tag-item:hover{
  border: none;
}

.guide-page .large-text {
  font-size: 90px; /* Увеличение размера шрифта на 20 пикселей */
}

.guide-page .guide-page-content h2{
  position: relative;
  border-bottom: 2px solid #3AA1FF;
  margin-bottom: 0.938rem;
  margin-top: 1.25rem;
}

.guide-page .guide-page-content h3{
  margin-bottom: 0.938rem;
  margin-top: 1.25rem;
}

.guide-page .guide-page-content ul{
  margin-top: 0.625rem;
}

.guide-page .guide-page-content ol{
  margin-top: 0.625rem;
}

.guide-page .guide-page-content .img-conteiner{
  display: flex;
}

.guide-page .guide-page-content img{
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  max-width: 50vw;
}

.guide-page .guide-page-content .img-conteiner img{
  max-width: 15vw;
  max-height: 15vh;
  margin-top: 0;
  margin-bottom: 0;
}

.guide-info-container .clear-filters{
  display: none;
}

.guide-info-container .filters-count{
  display: none;
}

@media (max-width: 810px) {
  .guide-info-container .search{
      order: 0
  }

  .guide-info-container .filters-count{
    display: flex;
  }

  .guide-info-container .clear-filters{
    order: 1;
    display: flex;
  }

  .guide-info-container .show-filters{
    order: 2
  }
}

@media (max-width: 490px) {
  .guide-info-container .search{
      order: 2
  }

  .guide-info-container .clear-filters{
    order: 1;
    display: flex;
  }

  .guide-info-container .show-filters{
    order: 0
  }
}

/* Заголовок гайда */
.guide-page .guide-header {
  width:  100%;
  color:white;
  background-color: #131319;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.guide-page .guide-header .guide-page-date {
  font-size: 1.25rem;
}

.guide-page .guide-header .guide-tags-container .tags-title {
  font-size: 1.5rem;
}

.guide-page .guide-header .guide-tags-container .dropdown-tag-item {
  font-size: 1.25rem;
}
/* .filters-modal-wrapper .dropdown-tags .tags-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filters-modal-wrapper{
  padding: 40px 40px 30px;
}
.filters-modal-wrapper .dropdown-tags .tags-container .dropdown-tag-item  {
  justify-content: center;
} */
.guide-page .guide-page-title {
  width:  100%;
  color:white;
  background-color: #131319;
  padding: 0rem 1.5rem;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.guide-page .guide-header .guide-tags-container {
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
.guide-page .guide-tags-container .dropdown-tag-item {
  cursor: default;
  font-size: 1rem;
}
.guide-page .guide-tags-container .dropdown-tag-item:hover {
  border: white 1px solid;
}
/* Дата последнего изменения */
.guide-page .guide-page-date {
  color: gray; /* Серый цвет для даты */
  font-size: 1rem; /* Размер шрифта для даты */
  width: 100%; /* Обеспечиваем, чтобы дата занимала всю ширину */
}

/* Заголовок для тегов */
.guide-page .tags-title {
  margin-right: 10px; /* Отступ между заголовком и тегами */
  font-size: 1.25rem;
}

/* Индивидуальный тег */
.guide-page .guide-tag {
  background-color: #25272d; /* Черный цвет контейнера для тэгов */
  color: white; /* Цвет текста тэгов */
  padding: 5px 10px; /* Внутренние отступы */
  margin: 5px 0; /* Убираем боковые отступы, оставляя только сверху и снизу */
  border-radius: 4px; /* Закруглённые углы */
  border: 1px solid #101113; /* Темный цвет при наведении */
}
/* Контейнер для основного содержимого */
.guide-page .guide-image{
  max-width: 100%;
}

.guide-page .guide-page-content {
  margin-top: 10px; /* Отступ между тегами и содержанием */
  width:  100%;
  color:white;
  background-color: #131319;
  padding: 5px 30px;
  border-radius: 8px;
  box-sizing: border-box;
  display: inline;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.guide-page .guide-page-content p {
  font-size: 1.5rem;

}
/* Описание страницы гайда */
.guide-page .guide-page-description {
  font-size: 16px; /* Размер текста описания */
  line-height: 1.5; /* Высота строки для удобочитаемости */
}

@media (max-width: 75rem) { /* 1200px = 75rem */
  .guide-page .guide-page-content p {
      font-size: 1.3rem;
  }
  .guide-page .guide-page-content img{
    max-width: 50vw;
  }
}
@media (max-width: 56.25rem) { /* 900px = 56.25rem */
  .guide-page .guide-page-content p {
    font-size: 1.2rem;
  }
  .guide-page .guide-page-content p {
    font-size: 1.3rem;
}
  .guide-page .guide-page-content img{
    max-width: 75vw;
  }
}
@media (max-width: 50rem) { /* 800px = 50rem */
  .guide-page .guide-page-content p {
    font-size: 1.15rem;
  }
}
@media (max-width: 46.875rem) { /* 750px = 46.875rem */
  .guide-page .guide-page-content p {
    font-size: 1.1rem;
  }
}
@media (max-width: 38.875rem) { /* 622px = 38.875rem */
  .guide-page .guide-page-content p {
    font-size: 1.1rem;
  }
  .guide-page .tags-title {
    font-size: 1.1rem;
  }
  .guide-page .dropdown-tag-item {
    font-size: 1.1rem;
  }
  .guide-page .guide-page-content .table-container{
    overflow-x: auto; 
    max-width: 100%;
  }
  .guide-page .guide-page-content .table-container table{
    width: 100%; 
    border-collapse: collapse;
  }
}
@media (max-width: 37.5rem) { /* 600px = 37.5rem */
  .guide-page .guide-info-item {
    font-size: 1rem;
  }
  .guide-page .dropdown-tag-item {
    font-size: 1rem;
  }
  .guide-page .guide-page-content p {
    font-size: 1.3rem;
  }
  .guide-page .guide-page-content img{
    max-width: 65vw;
  }
}

.guide-page .under_img{
  color: #b3b3b3;
}

@media (max-width: 810px){
  .guide-page-content{
    font-size: 20px;
  }
}

.guide-page .guide-page-content table{
  border-collapse: collapse;
}

.guide-page .guide-page-content table td{
  padding: 10px;
}

.guide-page .guide-page-content table th{
  padding: 10px;
  text-align: center;
}

.guide-page .guide-page-content table tr{
  text-align: center;
}

.guide-page .nav-link svg{
 margin-bottom: -3px;
 margin-left: 10px;
 transition: 0.5s;
 width: 20px;
 height: 20px;
}
.guide-page .nav-link path{
  stroke:white;
  opacity: 0.75;
  transition: 0.5s;
}
.guide-page .nav-link{
  color:rgba(255, 255, 255, 0.75);
  text-decoration: none;
  font-size: 24px;
  background-color: #25272D;
  padding: 20px;
  padding-right: 30px;
  box-sizing: border-box;
  flex-direction: row;
  gap: 12px;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  display: inline-block!important;
}

.guide-page .guide-page-content span a{
  color: #3AA1FF;
}

.guide-page .nav-link:hover svg{
  transform: translateX(10px);
}
.guide-page .nav-link:hover path{
  opacity:1;
}
.guide-page .nav-link:hover{
  background: none;
  color:rgba(255, 255, 255, 1);
}
.guide-page .nav-link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, #3AA1FF -174.48%, #211E29 32.15%);
  opacity: 0;
  transition: 0.5s;
}
.guide-page .nav-link:hover::before {
  opacity: 1;
}
.guide-page .nav-link ul{
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.guide-page .nav-link--animated{
  animation: navLinkAnimation 4s ease ;
}
.guide-page .nav-link--animated::before{
  animation: navLinkBeforeAnimation 2s ease ;
}
.guide-page .nav-link--animated svg {
  animation: navLinkSVGAnimation 2s ease ;
}
@keyframes navLinkSVGAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
      transform: translateX(10px);
  }
  100% {
      transform: translateX(0);
  }
}
@keyframes navLinkBeforeAnimation {
  0% {
    opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}
@keyframes navLinkAnimation {
  0% {
    transform: scale(1);
      background: none;

  }
  25% {
      transform: scale(1.05);
      background: none;

  }
  50% {
      transform: scale(1);
      background: none;
  }
  51% {
      background-color: #25272d00;
  }
  100% {
      background-color: #25272D;
  }
}

