.main-layout-container {
    width: 100%;
    min-height: 100vh;
    background-color: #1C1E23;
    display: flex;
    flex-direction: column;
    padding-left: 220px;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 96px;
    box-sizing: border-box;
    /* transition: 0.4s; */
}
.main-layout-container--minimized {
    padding-left: 64px;
}
@media (max-width: 1000px) {
    .main-layout-container{
        padding-left: 0px;
        padding-top: 70px;
    }
}