.search-container {
    display: flex;
    align-items: center;
    width: 100%; /* Установите ширину контейнера на 100% */
}

.search-input {
    flex: 1; /* Позволяет полю ввода занимать оставшееся пространство */
    padding: 10px; /* Увеличьте отступы, если нужно */
    border: 1px solid #ccc; /* Стилиграйте с границей по вашему желанию */
    border-radius: 4px; /* Закругленные углы */
    margin-right: 10px; /* Отступ справа для элемента поиска */
}
.dropdown-tag-item {
    display: flex;
    background-color: #25272d; /* Более серый цвет фона для тегов */
    color: white;
    border-radius: 50px;
    border: 1px solid #101113; /* Темный цвет при наведении */
    padding: 0.3rem 0.75rem; /* Уменьшение внутренних отступов */
    font-size: 1.25rem;
    cursor: pointer;
    transition: background-color 0.3s; /* Анимация фона и трансформации */
    text-align: center; /* Централизуйте текст, можно настроить */
    align-items: center; /* Централизуйте текст, можно настроить */

}
.tag-item {
    font-size: 1.25rem;
}
.tag-container {
    display: flex;
    flex-wrap: wrap; /* Позволяет тегам переходить на новую строку */
    max-width: calc(100% - 5px); /* Учитывайте отступы */
}
.tags-container {
    display: flex; /* Используйте сетку для размещения тегов */
    flex-wrap: wrap; /* Позволяет тегам переноситься на новую строку */
    max-width: 25rem;
    gap: 10px; /* Промежуток между тегами */
    padding-right: 0.5rem;
    padding-left: 0.1rem;
}
.tag-count-circle {
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    padding: 4px;
    margin-left: 5px;
    font-size: 20px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
}
.btn-clear-all-tags {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    color:#007bff;
}
.btn-clear-all-tags:hover {
    text-decoration: underline;
}
.selected-tags-count {
    color: white;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
}
.show-more-btn {
    background-color: #101113; /* Цвет кнопки */
    color: #ffffff; /* Цвет текста */
    padding: 4px 5px; /* Внутренние отступы */
    border-radius: 8px; /* Закругленные углы */
    border: 1px solid #101113; /* Темный цвет при наведении */
    font-size: 1.25rem;
}

.show-more-btn:hover {
    border: 1px solid #ffffff; /* Белый цвет при наведении */
}
/* Стили для выпадающего списка тегов */
.dropdown-tags {
    margin-top: 2.5rem;
    background-color: #131319;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Тень для выделения */
    border-radius: 5px;
    padding: 5px;
    position: absolute; /* Для выпадающего списка */
    z-index: 10; /* Чтобы список отображался поверх других элементов */
}
.dropdown-tags .dropdown-tag-item {
    display: flex; /* Показ тегов в блоке для лучшего отображения */
    text-align: center; /* Централизуйте текст, можно настроить */
    align-items: center; /* Централизуйте текст, можно настроить */
    border: 1px solid #101113; /* Темный цвет */
}
.dropdown-tags .dropdown-tag-item:hover {
    border: 1px solid white; /* Белый цвет при наведении */
}
.dropdown-tags .dropdown-tag-item.active {
    border: 1px solid #007bff; /* Синий цвет при наведении */
    background-color: #007bff;
}
.dropdown-tags .dropdown-tag-item.active:hover {
    border: 1px solid white; /* Белый цвет при наведении */
}
.guide-tags-container {
    margin-top: 0.5rem; /* Отступ сверху для отделения от изображения */
    margin-bottom: 0;
    clear: both; /* Принудительно сбрасываем обтекание */
}
@media (max-width: 810px) {
    .tag-list{
        display: none;
    }
}