.official-links {
    display: flex;
}

@keyframes offLinksAnimation {
    0% {
      transform: translateX(-30px);
      opacity: 0;
    }
    100% {
        opacity: 0.8;
    }
}
.official-links a{
    height: 50px;
    width: 50px;
    opacity: 0;
    transition: 0.4s;
    position: relative;
    margin-right: 30px;
    color:white;
    font-size: 0;
}
.official-links--animated{
    transition: 0.4s;
}
.official-links--animated:nth-of-type(1){
    animation: offLinksAnimation 0.5s ease forwards;
}
.official-links--animated:nth-of-type(2){
    animation: offLinksAnimation 0.5s ease 0.25s forwards;
}
.official-links--animated:nth-of-type(3){
    animation: offLinksAnimation 0.5s ease 0.5s forwards;
}
.official-links--animated:nth-of-type(4){
    animation: offLinksAnimation 0.5s ease 0.75s forwards;
}
.official-links--animated:nth-of-type(5){
    animation: offLinksAnimation 0.5s ease 1s forwards;
}
.official-links--animated:nth-of-type(6){
    animation: offLinksAnimation 0.5s ease 1.25s forwards;
}
.official-links a:hover{
    opacity: 1;
    transform: scale(1.2);
}
.official-links a svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }