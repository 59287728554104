.MDFormatedText-event{
    color: rgba(255, 255, 255, 0.9);
    font-size: 25px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    position: relative;
    max-width: 100%;
    column-gap: 4px;
}
.MDFormatedText-event .formated-wrapper{
    gap: 4px;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
    position: relative;
}
.MDFormatedText-event .formated-wrapper:hover{
   background-color: rgba(0, 0, 0, 0.5);
}

.MDFormatedText-event .big-img{
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0,-150%);
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    width: 100px;
    height: 80px;
}
.MDFormatedText-event .formated-wrapper:hover .big-img{
    transform: translate(0,-100%);
    opacity: 1;
 }
.MDFormatedText-event .small-img{
    width: 50px;
    height: 30px;
    object-fit: contain;
    margin-bottom: -7px;
}
.MDFormatedText-event figcaption{
    text-decoration: underline;
}
