.tier-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}

.tier-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.tier-list--ego-ids {
    width: 100%;
}

.tier-list--passives {
    width: 48%;
}

.tier-list-name {
    color: white;
    font-size: 38.4px;
    margin-bottom: 12.8px;
    margin-top: 0;
}
.tier-list-text-empty{
    color:white;
    font-size: 24px;
    font-weight: 500;
}
@media (max-width: 1000px) {
    .tier-list-container {
       flex-wrap: wrap;
    }
    .tier-list--passives {
        width: 100%;
    }
}
