.MDcrafts-wrapper{
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 15px;

}
.MDcrafts-wrapper h2{
   font-size: 34px;
   margin: 0;
}
.MDcrafts-sign{
    font-size: 45px;
    display: flex;
    align-items: center;
    
}
.MDcrafts-line{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    background-color: #131319;
    padding: 30px 50px;
    border-radius: 12px;
    box-sizing: border-box;
}

.MDcrafts-req{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;

}
.MDcrafts-result{
    display: flex;
    flex-direction: row;
}
.MDcrafts-wrapper img{
    width: 124px;
    height: 124px;
    object-fit: contain;
    background-color: #1d1d25;
}
.MDcrafts-wrapper picture{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    gap: 4px;
}
.MDcrafts-wrapper caption{
    width: 124px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 0px 5px;
    font-weight: 500;
    font-size: 24px;
}
.GiftItem-shadow{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 3;
}
.GiftItem-img-wrapper{
    position: relative;
    width: 124px;
    height: 124px;
    transition: 0.3s;
    will-change: scale,transform;
    border-radius: 14px;
    overflow: hidden;
    z-index: 4;
}
.GiftItem-img-wrapper:hover .GiftItem-shadow{
    background-color: rgba(0, 0, 0, 0.5);
}
.GiftItem-info{
    position: absolute;
    display: flex;
    align-items: center;
    top:0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 15%;
    text-align: center;
    color: white;
    z-index: 4;
    font-size: 24px;
    font-weight: bold;
    line-height: 20px;
    transform: scale(0);
    transition: 0.3s;
    will-change: scale transform;
}
.GiftItem-info--en{
    left: 0;
}
.GiftItem-info--ru{
    left: -8px;
}
.GiftItem-img-wrapper:hover .GiftItem-info{
    transform: scale(1);
}
.GiftItem-bar{
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
}
.GiftItem-bar--wrath{
    background-color: var(--wrath-frame-color);
}
.GiftItem-bar--lust{
    background-color: var(--lust-frame-color);
}
.GiftItem-bar--sloth{
    background-color: var(--sloth-frame-color);
}
.GiftItem-bar--glut{
    background-color: var(--glut-frame-color);
}
.GiftItem-bar--gloom{
    background-color: var(--gloom-frame-color);
}
.GiftItem-bar--pride{
    background-color: var(--pride-frame-color);
}
.GiftItem-bar--envy{
    background-color: var(--envy-frame-color);
}
.GiftItem-bar--none{
    background-color: var(--none-frame-color);
}
.GiftItem-img-wrapper .gift-keyword-img{
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    bottom: 0px;
    object-fit: contain;
    z-index: 2;
    background-color: unset !important;
}
.GiftItem-img-wrapper .gift-tier{
    color:#fbc601;
    text-shadow: 0px 0px 10px red;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    left: 10px;
    top:5px;
}
@media (max-width:650px) {
    .MDcrafts-line{
        padding: 12px 20px;
    }
    .MDcrafts-wrapper img{
        width: 62px;
        height: 62px;
    }
    .GiftItem-img-wrapper{
        width: 62px;
        height: 62px;
    }
    .GiftItem-img-wrapper .gift-keyword-img{
        width: 20px;
        height: 20px;
    }
    .GiftItem-img-wrapper .gift-tier{
        font-size: 20px;
    }
    .MDcrafts-wrapper caption{
        width: 62px;
        font-size: 10px;
    }
    .GiftItem-info{
        font-size: 10px;
        line-height: 12px;
    }
}