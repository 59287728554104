/* Стили для контейнера с заголовком */
.info-container {
    background-color: #131319;
    border-radius: 8px;
    box-sizing: border-box;
    color: white;
    width: 70rem; /* Ширина для описания */
    height: 18rem;
    flex-direction: column;
    justify-content: space-between;
}

/* Стили для контейнера с картинкой и текстом */
.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Стили для контейнера с картинкой и текстом */
.info_title {
    margin-left: 1rem;
}

.discrip {
    margin-left: 1.5rem;
}

/* Стили для контейнера с картинкой и текстом */
.image-container2 {
    background-color: #131319;
    border-radius: 8px;
    box-sizing: border-box;
    color: white;
    width: 20rem; /* Ширина для описания */
    height: 18rem;
    align-items: center;
    margin-left: 5rem;
}

/* Стили для текста под картинкой */
.prize-text {
    margin-top: 10px; /* Отступ сверху */
    text-align: center; /* Выравнивание текста по центру */
}

.tournament-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.button-list {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    overflow-x: auto;
}

.button-list button:hover {
    transform: scale(1); /* Увеличение размера кнопки */
    color: white; /* Изменение цвета текста */
}

.button-list ul {
    list-style: none;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    margin: 0;
}

.button-list li {
    width: fit-content;
    height: fit-content;
    margin-bottom: 8px;
    margin: 0 4.8px 0px 0px;
    color: rgba(255, 255, 255, 0.664);
    border-radius: 3.2px;
    background: #25272D;
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

.button-list button {
    height: 44px;
    min-width: 80px;
    width: fit-content;
    padding: 0 10px;
    border: none;
    text-decoration: none;
    transition: 0.4s;
    color: #AFAEB4;
    font-size: 19.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.button-list-line {
    width: 100%;
    height: 1.6px;
    background: #3AA1FF;
    position: absolute;
    bottom: 0;
}

.tournament-header {
    margin-top: 10px; /* Отступ между тегами и содержанием */
    width: 100%;
    color: white;
    background-color: #0A0A0A; /* Более темный фон */
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.content-with-guests {
    margin-top: 10px; /* Отступ между тегами и содержанием */
    display: flex; /* Используйте flex для горизонтального расположения */
}

.specific-list {
    color: white;
    background-color: #131319;
    border-radius: 8px;
    align-items: flex-start; /* Выравнивание текста слева сверху */
    width: 70rem; /* Ширина для описания */
}

.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 5rem;
    width: 20rem;
    color: white;
    background-color: #131319;
    border-radius: 8px;
}

/* Стили для формы авторизации */
.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #131319;
    border-radius: 8px;
    color: white;
    padding: 2rem;
    margin: 5rem auto;
    width: 90%; /* Ширина для мобильных */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.auth-message {
    margin-bottom: 2rem;
    font-size: 1.2rem;
    text-align: center;
}

.auth-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.auth-input input {
    width: 80%;
    height: 2.5rem;
    padding: 0 1rem;
    border: none;
    border-radius: 4px;
    margin-bottom: 1rem;
    background-color: #25272D;
    color: white;
    font-size: 1rem;
}

.auth-input button {
    width: 50%;
    height: 2.5rem;
    border: none;
    border-radius: 4px;
    background-color: #3AA1FF;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
}

.auth-input button:hover {
    background-color: #2187e7;
}

.auth-error {
    color: #ff5252;
    margin-top: 1rem;
    font-size: 1rem;
}




