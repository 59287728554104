.bingo-container {
    padding: 20px;
    text-align: center;
}

.color-selector {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.color-button {
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.color-button.yellow {
    background-color: #f7dc6f;
}

.color-button.purple {
    background-color: #7a288a;
}

.yellow {
    background-color: #f7dc6f;
}

.purple {
    background-color: #7a288a;
}

.split-color {
    background-image: linear-gradient(to bottom left, var(--top-color) 50%, var(--bottom-color) 50%);
}

.refresh-button {
    border: none;
    border-radius: 5px;
    background-color: #8f8f8f;
    color: white;
    font-weight: bold;
}

.refreshtext {
    color: rgb(0, 0, 0);
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.main-content {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Изменили на flex-start для выравнивания по верху */
    gap: 60px; 
}

.bingo-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px; /* Уменьшили отступы между кубиками */
    width: 520px; /* Немного уменьшили общую ширину */
    margin-left: 8rem;
}

.bingo-square {
    width: 120px;
    height: 120px;
    background-color: #ccc;
    border-radius: 12px;
    border: 2px solid #aaa;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
}

.bingo-square:hover {
    transform: scale(1.01);
}

.bingo-square-text {
    font-size: 15px;
    text-align: center;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    padding: 10px;
    word-break: break-word;
}

.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(95, 95, 95);
    padding: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 10px;
}

.confirmation-dialog button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgb(40, 40, 40);
}

.difficulty-controls {
    margin-top: 9rem;
    display: flex;
    align-items: center; /* Теперь выравниваем по горизонтали */
    gap: 5px; /* Отступ между слайдером и текстом */
    
}

.difficulty-slider {
    height: 200px;
    width: 30px;
    -webkit-appearance: slider-vertical;
    writing-mode: bt-lr;
    accent-color: #7a288a; /* Измените цвет ползунка на фиолетовый */
    background-color: #ccc; /* Цвет бегунка */
}


.difficulty-labels {
    display: flex;
    flex-direction: column;
    gap: 65px;
    font-size: 18px;
    font-weight: bold;
    
}