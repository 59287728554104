.MDEvents{
    width: 90%;
    display: flex;
    flex-direction: column;
    color:white;
    gap:20px;
}
.MDEvents h2{
    font-size: 34px;
    margin: 0;
}
.MDEvents .search {
   width: 100%;
   box-sizing: border-box;
}
.MDEvents .search input {
   width: 80%;
}
.MDEvents .search .btn-clear {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translate(0,-50%);
}
.MDEvents .search .btn-search {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
}
.MDEvents-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: #131319;
    padding: 20px 40px;
    gap:15px;
    border-radius: 14px;
    box-sizing: border-box;
}
.MDEvents-ego-gifts-span{
    margin-top: 8px;
    font-size: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.MDEvents-ego-gifts-section{
    width: 100%;
    display: flex;
    justify-content: left;
}
.gifts-list{
    list-style: none;
    font-size: 25px;
    margin: 0;
    padding: 0;
    gap: 4px;
    display: flex;
    flex-direction: column;
}
.gifts-list--no-gifts{
    font-size: 25px;
    margin: 0 9% 0;
    padding: 0;
    color: var(--wrath-frame-color);
    text-decoration: underline;
}
.MDEvents-ego-gifts-section .big-img--container{
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: translate(0,-150%);
    opacity: 0;
    transition: 0.3s;
    will-change: translate ;
}
.MDEvents-ego-gifts-section .big-img--wrapper{
    position: relative;
    pointer-events: none;
    width: 90px;
    height: 90px;
    will-change: translate ;

}
.MDEvents-ego-gifts-section .big-img--wrapper .gift-tier{
    color:#fbc601;
    text-shadow: 0px 0px 10px red;
    font-size: 45px;
    font-weight: bold;
    position: absolute;
    left: 10px;
    top:0px;
    will-change: translate ;

}
.MDEvents-ego-gifts-section .big-img--wrapper .gift-keyword-img{
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0px;
    bottom: 0px;
    object-fit: contain;
    z-index: 2;
}
.MDEvents-ego-gifts-section .big-img{
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    background: rgb(54, 54, 54);
    border-radius: 6px;
    border: 1px black solid;
    box-sizing: border-box;
}
.formated-wrapper{
    position: relative;
}
.MDEvents-ego-gifts-section .formated-wrapper:hover .big-img--container{
    transform: translate(0,-100%);
    opacity: 1;
 }
.MDEvents-ego-gifts-section .small-img{
    width: 22px;
    height: 22px;
    object-fit: contain;
    background: rgb(54, 54, 54);
    border-radius: 4px;
    margin-bottom: -4px;
}
.MDEvents-section--animated{
    animation: MDEvents-section--animation 2s 0.2s ease-in-out forwards;
}
@keyframes MDEvents-section--animation {
    0% {  box-shadow: 0 0 20px #3AA1FF};
    90% {  box-shadow: 0 0 10px #3AA1FF};
    100% {  box-shadow: 0 0 0px #3AA1FF};
}
.MDEvents-section header{
    display: flex;
    flex-direction: column;
    text-wrap: balance;
    width: 288px;
}
.MDEvents-section header img{
    object-fit: cover;
    object-position: 0% 0%;
    width: 288px;
    height: 162px;
    border-radius: 8px;
}
.MDEvents-section header h3{
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    margin: 0 0 7px 0;
}
@media (max-width: 900px) {
    .MDEvents-section header img{
        width: 250px;
        height: 140px;
    }
    .MDEvents-section{
        padding: 20px ;
    }
    .MDEvents-ego-gifts-section{
        width: 250px;
    }
}
@media (max-width: 765px) {
    .MDEvents-section{
        flex-direction: column;
    }
    .MDEvents-section header{
        justify-content: center;
        align-items: center;
    }
}