.list-ego{
    display: flex;
    width:90%;
    flex-direction: column;
    color:white;
}
.list-ego h2{
    font-size: 30px;
    margin-top:0 ;
    font-weight: 600;
}
.list-ego p{
    font-size: 24px;
    font-weight: 400;
}
.list-ego-span{
    font-size: 30px;
    margin-top:0 ;
    margin-bottom: 20px;
    font-weight: 600;
}