.error-info {
    top:20vh;
    min-width: 30vw;
    position: absolute;
    text-align: center;
    padding: 0px 15px 40px 15px;
    border: 5px solid #ee052c1b;
    border-radius: 5px;
    margin: 20px auto;
    max-width: 400px;
    box-sizing: border-box;
    filter: drop-shadow(2px 4px 6px black);
  }
  
  .error-info h2 {
    color: #a94442;
    font-size: 8vh;
  }
  
  .error-info p {
    color: #a94442;
    font-size: 4vh;

  }
  
  .error-info a {
    text-decoration: none;
    background-color: #d9534f;
    color: #fff;
    padding: 10px 20px;
    font-size: 4vh;
    border-radius: 5px;
    display: inline-block;
    margin-top: 10px;
  }
  
  .error-info a:hover {
    background-color: #c9302c;
  }
  