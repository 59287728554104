.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.691);
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    z-index: 200;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none;
    perspective: 1600px;
    user-select: none;
}

.modal--active{
    opacity: 1;
    pointer-events: all;
}

.modal__content{
    width: 800px;
    height: 600px;
    padding: 20px 52px 20px 52px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #1C1E23;;
    display: flex;
    flex-direction: row;
    overflow: auto;
    position: relative;
    transition: 0.5s;
}
@media(max-width:1450px){
    .modal__content{
        width: 600px;
    }
}
@media(min-width:1800px){
    .modal__content{
        width: 900px;
    }
}
@media(min-width:2100px){
    .modal__content{
        width: 1100px;
    }
}
.modal__slot{
    width: 330px;
    height: 500px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #1C1E23;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: 0.5s;
}

.modal-ego-container{
    display: flex;
    flex-direction: row;
}

.modal--active .modal__slot {

    transform: perspective(1600px) rotateY(20deg);
    pointer-events: auto;
}

.modal-identity img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    border-radius: 8px 8px;
}
.modal-identity{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px;
}
.modal-identity-shadow{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 3;
}
.modal-ego{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px;
}
.modal-ego-shadow{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
    z-index: 3;
}
.modal-identity:hover .modal-identity-shadow{
    background: rgba(0, 0, 0, 0.5);
}
.modal-ego:hover .modal-ego-shadow{
    background: rgba(0, 0, 0, 0.5);
}
.modal-info{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 15%;
    text-align: center;
    color: white;
    z-index: 4;
    font-size: clamp(16px, 3vw, 36px);
    font-weight: bold;
    line-height: 1.2;
    transform: scale(0);
    transition: 0.3s;
    will-change: scale transform;
}
.modal-identity:hover .modal-info{
    transform: scale(1);
}
.modal-ego:hover .modal-info{
    transform: scale(1);
}
.modal-info svg path{
    stroke:white;
    stroke-width: 1.5px;
  }
.modal-ego-container{
    margin-top: 12px;
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-ego{
    width: 18%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px 8px;
    user-select: none;
}
.modal-ego img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    border-radius: 8px 8px;
}
.modal-ego span {
    font-size: 32px;
    font-weight: 500;
    color:#717171;
}
.modal-empty{
    border: 1px dashed #717171;
}
.modal .clear-filters{
    position: static;
    order: -1;
}

@media(max-width:1100px){
    .modal__slot{
        display: none;
    }
    .modal--active .modal__content{
        transform:  rotateY(0deg);
    }
    .modal__content{
        padding: 20px;
        width: 85%;
    }

}
@media (max-width:650px) {
    .modal-info{
        font-size: 10px;
        line-height: 12px;
    }
}