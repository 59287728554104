.toggle {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 32px;
  box-sizing: border-box;
  user-select: none;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1C1E23;
  transition: 0.5s;
  border-radius: 4.8px;
  border: 1px solid white;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle input:checked + .toggle-slider {
  background-color: white;
  border: 1px solid rgb(1, 1, 1);
}

.toggle input:checked + .toggle-slider:before {
  background-color: #1C1E23;
  transform: translateX(38.4px);
}

.toggle-slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4.8px;
  top: 24%;
  background-color: white;
  transition: 0.5s;
  border-radius: 50%;
}

.status-text {
  position: absolute;
  font-size: 19.2px;
  font-weight: 600;
  top: 3.2px;
}
.status-text.on {
  left: 5.6px;

}
.status-text.on path{
  stroke: #1C1E23;
}

.status-text.off {
  right: 5.6px;
}
.status-text.off path{
  stroke: white;
}
