.filters{
  width: 90%;
  margin-bottom: 38.4px;
  position: relative;
  user-select: none;
}
.filters-main-header{
  font-size: 32px;
  font-weight: 600;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38.4px;
}
.filters-main-header--collapse{
  margin-bottom: 0px;
}
.filters-main{
  display: flex;
  flex-wrap: wrap;
  column-gap: 19.2px;
  row-gap: 35px;
}
.btn-filters{
  display:flex;
  align-items: center;
  color:rgba(255, 255, 255, 0.8);
  background-color: #25272D;
  padding: 10px;
  box-sizing: border-box;
  font-weight: 400;
  gap:8px;
  transition: 0.4s;
}
.show-filters{
  display:none;
  font-size: 16px;
  height: 43.6px ;
}
.btn-filters svg{
  width: 23.6px ;
  height: 23.6px ;
}
.btn-filters path{
  fill:rgba(255, 255, 255, 0.801);
  transition: 0.4s;
}
.show-filters path{
  fill: rgba(255, 255, 255, 0.801);
  stroke-width: 1.2px;
  stroke:none;
}
.btn-filters:hover path{
  fill:white;
}
.clear-filters{
  font-size: 16px;
  height: 43.6px ;

}
.clear-filters svg{
  width: 18px ;
  height: 18px ;
}
.clear-filters:hover{
  color:white;
}
.modal__content .filters{
  width: 100%;
}
.filters section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  gap:3.2px;
}

.filters .section-expanded{
  display: grid !important;
  gap: 3.2px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 43.2px) !important;
  justify-content: space-between !important;
}

.filters-filter img{
  width: 27.2px;
  height: 27.2px;
  object-fit: contain;
}
.filters-filter{
  width: 43.2px;
  height: 43.2px;
  color:white;
  font-size: 19.2px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 3.2px;
  cursor: pointer;
  position: relative;
  background: #25272D;
  transition: 0.3s;
}

.filters-filter-tooltip{
  display: block; 
  position: absolute;
  width: max-content;
  max-width: 95px;
  text-align: center;
  background-color: #333;
  color: #fff;
  font-size: 14.4px;
  padding: 4px 6px;
  border-radius: 4px;
  z-index: 2;
  font-weight: 600;
  top: -1.6px;
  transform: translate(0,-150%);
  pointer-events: none;
  opacity: 0;
  transition: 0.4s;
  border: 1px solid black;
  box-sizing: border-box;
  will-change: opacity , tranform;
}

.filters-filter:hover{
  box-shadow: 0px 6.4px 13.6px 1.6px rgba(0,0,0,0.14), 0px 2.4px 11.2px 1.6px rgba(0,0,0,0.12), 0px 4px 4px -2.4px rgba(0,0,0,0.2);
}

.filters-filter:hover .filters-filter-tooltip {
  opacity: 1;
  transform: translate(0,-100%);

}
.filters-filter--active{
  background: linear-gradient(180deg, #474A56 0%, rgba(71, 74, 86, 0.00) 193.52%);
}
.filters-frame-line{
  width: 100%;
  height: 1.6px;
  background: #3AA1FF;
  position: absolute;
  bottom: 0; 
}
.filters-clear-section{
  width: 16px;
  height: 16px;
 
}
.filters-clear-section svg path {
  fill: rgb(233, 230, 230);
  opacity: 0.7;
  transition: 0.32s;
}
.filters-clear-section:hover svg path {
  opacity: 0.95;
}
.filters-modal-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80vw;
  max-height: 60vh;
  background-color: #1C1E23;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px 40px 30px;
  overflow: auto;
  column-gap:16px;
  row-gap:30px;

}
.filters-section header {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -28px;
  color:white;
  font-weight: 500;
  height: 32px;
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.filters-header-btns{
  display:flex;
  align-items:center;
  justify-content:center;
  gap:0.75rem;
  flex-wrap: wrap;
}
@media (max-width: 810px) {
  .clear-filters{
    position: static;
    order: -1;
  }
  .filters-main-header{
    flex-direction: column;
    gap: 14px;
    margin-bottom: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .show-filters{
    display: flex;
    position: static;
    order: -1;
  }
  .filters section {
    display: none;
  }
  .filters-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    gap:3.2px;
  }
}
@media (max-width: 490px) {
  .filters-header-btns{
    flex-direction: column-reverse;
    gap: 14px;
    margin-bottom: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.filters-show-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transition: 0.45s;
}
.filters-show-btn:hover{
  opacity: 1;
}
.filters-show-btn svg {
  width: 20px;
  height: 20px;
}
.filters-show-btn svg path{
  stroke:white;
  stroke-width: 1px;
}