.item-entity-container {
    width: 138.4px;
    height: 167.2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateX(-16px);
    opacity: 0;
    cursor: pointer;
    border-radius: 3.2px 3.2px 0px 0px;
    position: relative;
    z-index: 5;
}
.item-entity-container--animated {
    transform: translateX(0);
    opacity: 0.8;
}
.item-entity-container:hover {
    opacity: 1;
    z-index: 6;
}
.item-entity-container:hover .item-ego-info-container,
.item-entity-container:hover .item-identity-info-container{
    animation: info_in_animation 0.5s forwards ;
    display: flex;
}

@keyframes info_in_animation{
    100%{
        transform: translate(-50%, -102%) ;
        opacity: 1;
    }
}
.item-entity-container:hover .item-entity-image{
    transform: scale(1.05);
}
.item-entity-image{
    width:  100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative; 
    border-radius: 6.4px 6.4px;
    transition: 0.48s;
    will-change: transform;
}

.item-entity-container .shadow {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.4px 6.4px;
    overflow: hidden;
}
.item-entity-new{
    position: absolute;
    right: 3.84px;
    top: 3.84px;
    width: fit-content;
    padding: 2px 6px;
    box-sizing: border-box;
    background-color: rgb(255, 57, 57);
    color: white;
    border-radius: 4px;
}


.item-entity-rarity {
    position: absolute;
    z-index: 102;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    border-radius: 3.2px;
    background: rgba(0, 0, 0, 0.40);
    padding: 1.6px 5.12px 1.6px;
    margin: 3.84px 0px 0px 3.84px;
    font-size: 16px;
    color: var(--O-color);
}

.item-entity-frame {
    width: 100%;
    height: 2.4px;
    position: absolute;
    bottom: 0;
    z-index: 102;
}

.item-entity-frame--OOO {
    background: var(--OOO-frame-color);
}

.item-entity-frame--OO {
    background: var(--OO-frame-color);
}

.item-entity-frame--O {
    background: var(--O-frame-color);
}


.item-entity-name {
    color: #FFF;
    font-size: 19px;
    margin-bottom: 7.68px;
    box-sizing: border-box;
    text-align: left;
    padding: 0px 9.6px 0px 9.6px;
    position: absolute;
    bottom: 0;
    z-index: 102;
    font-weight: 600;
}

@media (max-width: 1039.5px) {
    .item-entity-container {
        width: 124.56px;
        height: 150.48px;
    }
    .item-entity-name {
        font-size: 14.4px; 
        padding: 0px 8.64px 0px 8.64px;
        margin-bottom: 6.9px;
    }
    
    .item-entity-rarity {
        padding: 1.44px 4.608px 1.44px;
        margin: 3.456px 0px 0px 3.456px;
        font-size: 14.4px;
    }
    
}
@media (max-width: 810px) {
    .item-entity-container {
        width: 106.568px; 
        height: 128.744px;
    }
    
    .item-entity-name {
        font-size: 12.32px; 
        padding: 0px 7.392px 0px 7.392px; 
        margin-bottom: 5.72px; 
    }
    
    .item-entity-rarity {
        padding: 1.232px 3.9424px 1.232px;
        margin: 2.9576px 0px 0px 2.9576px; 
        font-size: 12.32px;
    }
    .item-entity-new{
        padding: 1px 4px;
        font-size: 12px;
    }
}
@media (max-width: 380px) {
    .item-entity-container {
        width: 88.60776px;
        height: 106.60208px; 
    }
    
    .item-entity-name {
        font-size: 10.2324px; 
        padding: 0px 6.12456px 0px 6.12456px; 
        margin-bottom: 4.8464px; 
    }
    
    .item-entity-rarity {
        padding: 1.02844px 3.351168px 1.02844px; 
        margin: 2.514672px 0px 0px 2.514672px; 
        font-size: 10.2324px; 
    }
   
    
}