.GiftsList-wrapper{
    width: 100%;
    color:white
}
.GiftsList-wrapper h2{
    font-size: 34px;
}
.GiftsList-wrapper .main-img{
    width: 124px;
    height: 124px;
    object-fit: contain;
    background-color: #131319;
}
.GiftsList-wrapper .keyword-img{
    width: 30px;
    height: 30px;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
}
.GiftsList-wrapper figure{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    gap: 4px;
    margin: 0;
    padding-bottom: 10px;
}
.GiftsList-img-wrapper{
    position: relative;
    width: 124px;
    height: 124px;
    transition: 0.3s;
    will-change: scale,transform;
    border-radius: 14px;
    overflow: hidden;
}
.GiftsList-shadow{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.3s;
}
.GiftsList-img-wrapper:hover .GiftsList-shadow{
    background-color: rgba(0, 0, 0, 0.5);
}
.GiftsList-img-wrapper:active{
    transform: scale(0.9);
}
.GiftsList-img-wrapper svg{
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    z-index: 3;
    transition: 0.3s;
    transform: translate(-50%,-50%) scale(0);
}
.GiftsList-img-wrapper:hover svg{
    transform: translate(-50%,-50%) scale(1);
}

.GiftsList-wrapper figcaption{
    width: 124px;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 0px 5px;
    font-weight: 500;
    font-size: 24px;
}
.GiftsList-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.GiftsList-header{
    background-color: #131319;
    width: 100%;
    padding: 10px 40px;
    box-sizing: border-box;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    border-radius: 4px;
    color:#fbc601;
    text-shadow: 0px 0px 10px red;
}

.GiftsList-header svg{
    transition: 0.3s;
    transform: rotate(180deg);
    stroke:#fbc601;
    filter: drop-shadow(2px 2px 4px red); 

}
.GiftsList-header svg path{
    stroke:#fbc601;
}
.GiftsList-header--hidden svg{
    transform: rotate(0deg);
}
.GiftsList-content{
    gap: 10px;
    transition: 0.3s;
    display: grid;
    grid-template-columns: repeat(auto-fill, 124px); /* Автоматическое повторение колонок с шириной 124px */
    grid-gap: 5px; /* Примерный внешний отступ блока */
    justify-content: space-between;
    margin-bottom: 20px;
}
.GiftsList-content--hidden{
    height: 0;
    overflow: hidden;
}
.GiftsList-bar{
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
}

.GiftsList-bar--wrath{
    background-color: var(--wrath-frame-color);
}
.GiftsList-bar--lust{
    background-color: var(--lust-frame-color);
}
.GiftsList-bar--sloth{
    background-color: var(--sloth-frame-color);
}
.GiftsList-bar--glut{
    background-color: var(--glut-frame-color);
}
.GiftsList-bar--gloom{
    background-color: var(--gloom-frame-color);
}
.GiftsList-bar--pride{
    background-color: var(--pride-frame-color);
}
.GiftsList-bar--envy{
    background-color: var(--envy-frame-color);
}
.GiftsList-item-selected-info{
    padding: 0px 5px;
    font-weight: 800;
    border: 4px solid green;
    font-size: 30px;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top:50%;
    color: green;
    background-color: rgba(0, 0, 0, 1);
    transform: translate(-50%,-50%);
    opacity: 0.9;
}