.tb-sins{
    width: 90%;
    display: flex;
    flex-direction: column;
    z-index:2;
    z-index: 113;
}
.tb-sins-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:4px;
}
.tb-sin--spent {
    color: red;
}
.tb-sins-header{
    font-size: 24px;
    color: white;
    margin: 0;
    margin-bottom: 4px;
}
.tb-sins-sin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #25272D;
    border-radius: 4px;
    width: 65px;
    height: 65px;
    color: white;
    position: relative;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}
.tb-sins-sin--active{
    box-shadow: 0 0 12px #3AA1FF; 
}
.tb-sins-img{
    width:30px;
    height:30px;
    object-fit: contain;
    margin-bottom: 4px;
}
.tb-sins-req{
    color:red;
    
}
.tb-sins-pres{
    color:green;
}
@media(max-width:1000px){
    .tb-sins{
        z-index: 90;
    }
}