.tb-slots{
    width: 90%;
    color:white;
    position: relative;
}
/* Add these to your TbSlots.css file */
.tb-slots-download,
.tb-slots-upload {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #25272D;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.tb-slots-download:hover,
.tb-slots-upload:hover {
    background-color: #2d3748;
}

.tb-slots-download:active,
.tb-slots-upload:active {
    background-color: #1a202c;
}
.tb-slots-container{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 19.2px;
    padding: 16px 1.6px;
    position: relative;
    transition:0.5s;
    padding-top: 24px;
    user-select: none;
    overflow: hidden;
}
.tb-slots-container--flex{
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition:0.5s;
    opacity: 1;
}
.tb-slots-container--grid{
    display: grid;
    grid-template-columns: repeat(auto-fill,208px);
    justify-content: space-between;
    grid-row-gap: 25.6px;
    transition:0.5s;
    opacity: 1;
}
.tb-slots-container--gloving{
    box-shadow: 0 0 4px #3AA1FF;
}
.tb-slots-slot{
    display: flex;
    flex-direction: column;
    background: #25272D;
    padding: 16px;
    width: 176px;
    position: relative;
    border-radius: 6.4px;
    cursor: pointer;
    transition: 0.5s;
    margin-right: 16px;
    animation: AppearAnimationSlot 0.5s ease;
}
@keyframes AppearAnimationSlot {
    0% {
        opacity: 0;
        transform: translateX(-16px);
    }
    100% {
        opacity: 1;
    }
}
.tb-slots-slot:last-of-type{
    margin-right: 0px;
}
.tb-slots-slot--active{
    box-shadow: 0 0 9.6px #3AA1FF;
}
.tb-slots-container-id{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 176px;
    height: 200px;
    box-sizing: border-box;
    transition: box-shadow 0.24s ease;
}
.tb-slots-container-id div {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6.4px 6.4px;
}
.tb-slots-container-id img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    border-radius: 6.4px 6.4px;
}
.tb-slots-container-id--active{
    box-shadow: 0 0 9.6px #3AA1FF;
}
.tb-slots-container-ego{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.2px;
}
.tb-slots-slot-ego{
    width: 29.6px;
    height: 29.6px;
    border-radius: 3.2px;
    margin-top: 3.2px;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.24s ease;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}
     
.tb-slots-slot-ego .shadow {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.tb-slots-slot-ego .shadow::after{
    border-radius: 3.2px;
}
.tb-slots-slot-ego img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    border-radius: 3.2px;
}
.tb-slots-slot-ego span {
    color:rgb(111.2, 108, 108);
    font-size: 19.2px;
    font-weight:500;
}
.tb-slots-slot-ego--active{
    box-shadow: 0 0 9.6px #3AA1FF;
}
.tb-slots-id-sin{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.408);
}
.tb-slots-id-sin div {
    width: 26.624px;
    font-size: 8.32px;
    height: 9.6px;
    display: flex;
    padding-top: 2.4px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}
.tb-ego-frame{
    width: 100%;
    height: 1.6px;
    position:absolute;
    bottom: 0;
}
.tb-slots-id-img{
    width: 100%;
    height: 100%;
    border-radius: 3.2px;
}
.tb-slots-empty{
    border: 0.8px dashed #717171;
}
.tb-slots-X{
    width: 22.4px;
    height: 22.4px;
    position: absolute;
    top: -22.4px;
    right: 0;
    color:#717171;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 12.8px;
    box-sizing: border-box;
    padding: 3.2px 0px 0px 1.6px;
    cursor: pointer;
    transition: 0.4s;
}
.tb-slots-X:hover{
    color:white;
}

.tb-slots-reset{
    height: 32px;
    box-sizing: border-box;
    border-radius: 4.8px;
    top:16px;
    right: 0px;
    cursor: pointer;
    color: #3AA1FF;
    border: 0.8px solid #3AA1FF;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 19.2px;
    transition: 0.4s;
    font-weight: 480;
    padding-right: 5px;
    box-sizing: border-box;
}
.tb-slots-reset:hover{
    color:#131319;
    background-color: white;
    border: 0.8px solid #1C1E23;
}
.tb-slots-reset svg path{
    transition: 0.4s;
    stroke:#3AA1FF;

}
.tb-slots-reset:hover svg path{
    stroke:#131319;
}
.tb-slots-toggle{
    top:16px;
    right: 96px;
    cursor: pointer;
}

.tb-slots-tooltip {
    display: none;
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    z-index: 1000;
    top: -25.6px;
    left: 50%;
    transform: translateX(-50%);
    animation: fadeIn 0.5s ease;
}

.tb-slots-btnLeft {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 5;
    transform: translateX(calc(-100% - 16px));
    opacity: 1;
    transition: 0.4s;
}

.tb-slots-btnRight {
    position: absolute;
    right: 0;
    z-index: 5;
    top: 50%;
    transform: translateX(calc(100% + 16px));
    opacity: 1;
    transition: 0.4s;
}

.tb-slots-btnSlotText {
    padding: 8px 0;
    font-size: 24px;
}

.tb-slots-btnSlotCount {
    border: 1px solid #3AA1FF;
    border-radius: 4.8px;
    width: fit-content;
    height: fit-content;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color:#3AA1FF;
}

.tb-slots-btnSlotCount button,
.tb-slots-btnSlotCount span {
    padding: 0 8px;
    font-size: 19.2px;
}

.hidden {
    opacity: 0;
}
.tb-slots-collapse-wrapper{
    flex-direction: row;
    align-items: center;
}
@media(max-width:600px){
    .tb-slots-collapse-wrapper{
        flex-direction: column;
        align-items: flex-start;
    }
}
@media(max-width:1000px){
    .tb-slots-btnLeft , .tb-slots-btnRight{
        display: none;
    }
}
@media(max-width:650px){
    .tb-slots-toggle{
        display: none;
    }
}
@media(max-width:386px){
    .tb-slots-reset{
        top: -65px;
        right: 10px;

    }
    .tb-slots-btnSlotCount{
        right: 10px;
        left: unset;
    }
}
