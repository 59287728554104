.entityFullInfo-skills{
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (max-width:1200px) {
    .entityFullInfo-skills{
        width: 100% ;
    }
}

.entityFullInfo-skills .entityFullInfo-tooltip{
    max-width: 190px;
    text-overflow: ellipsis; 
}
.entityFullInfo-tabs{
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 10px;
    padding: 0px;
    margin: 0px;
    color: white;
    overflow-x: scroll;
}

.entityFullInfo-tab{
    background: #131319;
    padding: 10px 30px;
    box-sizing: border-box;
    border-radius: 5px;
    color: #AFAEB4;
    position: relative;
    cursor: pointer;
    font-size: 18px;
    transition: 0.35s color;
    font-weight: bold;
}
.entityFullInfo-tab:hover{
    color: #ffffff;
}
.entityFullInfo-tab--active{
    background: linear-gradient(0deg, #3AA1FF -100.584%, #131319 25.72%);
    color: #fff !important;
}
.entityFullInfo-tab-line{
    width: 100%;
    height: 1.6px;
    background: #3AA1FF;
    position: absolute;
    bottom: 0;
    left: 0;
}