.todo-section h2{
    margin-bottom: 0px;
}
.todo-section p{
    font-weight: 400;
    margin: 0;

}
.todo-section li{
    font-size: 34px;
    opacity: 0;
    font-weight: 600;
}
.todo-section--animated{
    opacity: 0;
    animation: toDOAnimation 1.5s ease forwards ;
}
@keyframes toDOAnimation {
    0% {
      transform: translateX(-30px);
      opacity: 0;
    }
    100% {
        opacity: 1;
    }
}