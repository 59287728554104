.H1Component{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.H1Component h1{
    width: max-content;
    /* white-space: nowrap; */
    color: white;
    margin-right: 10px;
}