.registration-form-container {
    margin: 1.5rem;
    padding: 1rem;
    background-color: #1a1a22;
    border-radius: 6px;
}

.registration-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-weight: bold;
    color: #AFAEB4;
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #25272D;
    background-color: #25272D;
    color: white;
    font-size: 1rem;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.submit-button {
    padding: 0.75rem;
    background-color: #3AA1FF;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
    width: fit-content;
    min-width: 150px; /* Минимальная ширина кнопки */
    
}


.submit-button:hover {
    background-color: #2187e7;
    transform: scale(1); /* Увеличение размера кнопки */
}

.submit-button:disabled {
    background-color: #7f7f7f;
    cursor: not-allowed;
}

.form-error {
    color: #ff5252;
    padding: 0.5rem;
    background-color: rgba(255, 82, 82, 0.1);
    border-radius: 4px;
}

.registration-success,
.registration-submitted {
    text-align: center;
    padding: 2rem;
}

.registration-success h3,
.registration-submitted h3 {
    color: #3AA1FF;
    margin-bottom: 1rem;
}
